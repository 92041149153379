import React from "react"
import styled from "@emotion/styled"

import { Link } from "gatsby"

const Container = styled.section`
  max-width: 130rem;
  margin: 0 auto;
  padding: 3rem;
`
const Wrapper = styled.table`
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 2px 6px rgba(0, 21, 64, 0.25);
  @media only screen and (max-width: 45em) {
    display: block;
    overflow-x: scroll;
  }
`

const Header = styled.thead`
  background-color: #fafafa;
`

const Row = styled.tr``

const Head = styled.th`
  padding: 2rem;
  min-width: 15rem;
  font-weight: 400;
  text-align: left;
  span {
    font-weight: 700;
    padding: 0.5rem;
    border-radius: 5px;
    color: #fafafa;
  }
  &:nth-of-type(2) {
    span {
      background: #8cc949;
    }
  }
  &:nth-of-type(3) {
    span {
      background: #ffca28;
    }
  }
  &:nth-of-type(4) {
    span {
      background: #ef5350;
    }
  }
  &:nth-of-type(5) {
    span {
      background: #66bb6a;
    }
  }
`

const Category = styled(Head)`
  text-align: center;
`

const StateTerritory = styled(Head)`
  font-weight: 700;
  background-color: #afafaf;
  color: white;
`

const TBody = styled.tbody`
  tr {
    &:nth-of-type(2n + 1) {
      background-color: rgba(0, 81, 193, 0.05);
    }
  }
`

const Cell = styled.td`
  text-align: center;
  padding: 2rem;
  &:nth-of-type(2n) {
    background-color: rgba(0, 81, 193, 0.05);
  }
  min-width: 21rem;
`

const Yes = styled.span`
  position: relative;
  height: 3rem;
  width: 3rem;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  display: block;
  margin: 0 auto;
  &::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 45%;
    width: 0.75rem;
    height: 1.5rem;
    border: solid #fafafa;
    border-width: 0 3px 3px 0;
    transform: translate(-50%, -50%) rotate(45deg);
  }
`

const No = styled.span`
  position: relative;
  height: 3rem;
  width: 3rem;
  background-color: #afafaf;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    height: 3px;
    width: 1.5rem;
    background-color: #fafafa;
  }
  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`

const Primary = styled.h2`
  text-align: center;
  font-size: 3.5rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.3;
  color: #8cc949;
  background: linear-gradient(to right, #8cc949, #78b526);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
`

const Subtitle = styled.h3`
  font-size: 1.9rem;
  font-weight: 400;
  text-align: center;
  padding: 1.5rem 10rem;
  @media only screen and (max-width: 52em) {
    padding: 1.5rem 0;
  }
`

const Content = styled(Head)`
  padding: 1.5rem;
`
const Secondary = styled.h4`
  font-size: 2.5rem;
  font-weight: 700;
  color: #505050;
`

const To = styled(Link)`
  color: #8cc949;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

const Table = ({ categories, data }) => {
  function toUrl(str) {
    // Replace spaces OR comma followed by space OR and symbols surrounded by spaces with dashes
    return str.replace(/ \& |\s|, /g, "-").toLowerCase()
  }

  function toSentenceCase(string) {
    const text = string.replace(/([A-Z])/g, " $1")
    return text.charAt(0).toUpperCase() + text.slice(1)
  }

  function sortString(a, b) {
    if (!a || !b) {
      return
    }
    const strA = a.toUpperCase()
    const strB = b.toUpperCase()

    let comparison = 0
    if (strA > strB) {
      comparison = 1
    } else if (strA < strB) {
      comparison = -1
    }
    return comparison
  }

  function parseCategories({ city, services }, stateTerritory, count) {
    if (!city || !services) {
      return
    }

    let urlPrefix = "recycling"
    if (
      stateTerritory === "queensland" ||
      stateTerritory === "westernAustralia"
    ) {
      urlPrefix = "containers-for-change"
    } else if (stateTerritory === "australianCapitalTerritory") {
      urlPrefix = "return-it"
    } else if (stateTerritory === "newSouthWales") {
      urlPrefix = "return-and-earn"
    }

    return (
      <Row key={count}>
        <Head>
          <To to={`/locations/${urlPrefix}-${toUrl(city)}`}>{city}</To>
        </Head>
        <Cell>
          {services.includes("Beverage") ? <Yes color="#8cc949" /> : <No />}
        </Cell>
        <Cell>
          {services.includes("Cell Phones") ? <Yes color="#ffca28" /> : <No />}
        </Cell>
        <Cell>
          {services.includes("Electronics") ? <Yes color="#ef5350" /> : <No />}
        </Cell>
      </Row>
    )
  }

  function renderStateTerritories() {
    let stateTerritories = []
    for (let stateTerritory in data) {
      stateTerritories.push(
        <React.Fragment>
          <Row>
            <StateTerritory colSpan="5">
              {toSentenceCase(stateTerritory)}
            </StateTerritory>
          </Row>
          {data[stateTerritory]
            .sort((a, b) => sortString(a.city, b.city))
            .map((cityDatum) => parseCategories(cityDatum, stateTerritory))}
        </React.Fragment>
      )
    }

    return stateTerritories
  }

  return (
    <>
      <Primary>Current Operations</Primary>
      <Subtitle>
        ReCollect is always expanding, check below to see if our pickup
        recycling service is in your city. If you are interested in bringing
        ReCollect to your city, <To to="/contact">send us a message.</To>
      </Subtitle>
      <Container>
        <Wrapper>
          <Header>
            <Row>
              <Content colSpan="5">
                <Secondary>Australia</Secondary>
              </Content>
            </Row>
          </Header>
          <Header>
            <Row>
              <Head>&nbsp;</Head>
              {categories.map((category, count) => (
                <Category key={count}>
                  <Link to="/recycling" style={{ textDecoration: "none" }}>
                    <span>{category}</span>
                  </Link>
                </Category>
              ))}
            </Row>
          </Header>
          <TBody>{renderStateTerritories()}</TBody>
        </Wrapper>
      </Container>
    </>
  )
}

export default Table
